.animate-wiggle {
    & > svg {
        height: 40px;
        border-radius: 2px;
        animation-name: wiggle;
        animation-duration: 2.5s;
        animation-timing-function: linear;
        animation-iteration-count: 1;
    }
}
/* Keyframes */
@keyframes wiggle {
    0%, 7% {
      transform: rotateZ(0) scale(1, 1);
    }
    15% {
      transform: rotateZ(-25deg) scale(2.5, 2.5);
    }
    20% {
      transform: rotateZ(19deg);
    }
    25% {
        
      transform: rotateZ(-19deg);
    }
    30% {
      transform: rotateZ(15deg);
    }
    35% {
      transform: rotateZ(-13deg);
    }
    40%, 100% {
      transform: rotateZ(0) scale(1, 1);
    }
  }